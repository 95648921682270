import React, { ReactElement } from "react";
import Modal from "react-modal";
import { css } from "@emotion/react";

import pxToRem from "../../styles/px-to-rem";
import CircleCloseButton from "./circle-close-button";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(45, 57, 65, 0.65)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    width: pxToRem(624),
    maxWidth: "95%",
    overflow: "visible",
    border: "0",
  },
};

const videoContainer = css`
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
`;

const videoPlayer = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;

const closeButton = css`
  position: absolute;
  top: 0;
  right: -${pxToRem(60)};
`;

type Input = {
  type: "wistia";
  title: string;
  videoID: string;
  isOpen: boolean;
  shouldCloseOnOverlayClick?: boolean;
  onRequestClose: () => void;
};

const VideoModal = ({
  type,
  title,
  videoID,
  isOpen = false,
  onRequestClose,
  shouldCloseOnOverlayClick = true,
}: Input): ReactElement => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Watch video"
      style={customStyles}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      <CircleCloseButton style={closeButton} onClick={onRequestClose} />

      {type === "wistia" && (
        <div css={videoContainer}>
          <iframe
            css={videoPlayer}
            title={title}
            src={`//fast.wistia.net/embed/iframe/${videoID}`}
            frameBorder="0"
            scrolling="no"
            className="wistia_embed"
            name="wistia_embed"
            allowFullScreen
            width="620"
            height="349"
          />
        </div>
      )}
      <script src="//fast.wistia.com/assets/external/E-v1.js" async />
    </Modal>
  );
};

export default VideoModal;
